import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cls } from "~/old-app/utils/helpers/cls";

const textVariants = cva("inline-block font-normal", {
  variants: {
    variant: {
      default: "text-main",
      primary: "text-primary",
      secondary: "text-secondary",
      text: "text-muted",
      background: "text-background",
      base: "text-border",
      link: "text-link hover:cursor-pointer",
      success: "text-success",
      warning: "text-warning",
      error: "text-error",
      info: "text-info",
      light: "text-light",
      dark: "text-dark-100",
    },
    size: {
      default: "text-md",
      base: "text-base",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-md",
      lg: "text-lg",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      "4xl": "text-4xl",
      "5xl": "text-5xl",
      "6xl": "text-6xl",
      "7xl": "text-7xl",
      "8xl": "text-8xl",
      "9xl": "text-9xl",
    },
    weight: {
      light: "font-light",
      regular: "font-normal",
      semibold: "font-semibold",
      bold: "font-bold",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export type TextProps = React.HTMLAttributes<HTMLParagraphElement> &
  VariantProps<typeof textVariants> & {
    asChild?: boolean;
  };

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  (
    { className, variant, size, weight, asChild = false, children, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "p";
    return (
      <Comp
        className={cls("", textVariants({ variant, size, weight, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);
Text.displayName = "Text";

export { Text, textVariants };
