import { FC, PropsWithChildren } from "react";
import { cls } from "~/old-app/utils/helpers/cls";

export type LabelProps = PropsWithChildren & {
  label?: string;
  className?: string;
  labelClassName?: string;
  required?: boolean;
};

export const Label: FC<LabelProps> = ({
  label,
  className = "",
  required,
  labelClassName,
}) => (
  <div className={cls(`flex gap-x-2 cursor-pointer`, className)}>
    <p
      className={cls(
        "text-gray-700 text-3xs font-semibold pb-1 flex gap-1",
        labelClassName
      )}
    >
      {label} {!!required && <p className="text-error-500 text-sm">*</p>}
    </p>
  </div>
);
